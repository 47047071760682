@import 'styles/utils/colors';
@import 'styles/utils/mixins';
@import 'styles/utils/sizes';

.form {
  @include flex(row, space-between, center);

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  gap: 12px;
  flex: 1 1 50%;

  .input-wrapper {
    width: 100%;
  }

  .input {
    @include fontSize(16px, 150%);

    padding: 16px 14px;
    width: 100%;
    line-height: 24px;
    background-color: $gray800;
    border-radius: 8px;
    color: $gray0;
    outline: none;
    border: 1px solid transparent;

    &::placeholder {
      @include fontSize(16px, 150%);

      color: $gray500;
      font-family: $default-font;
      font-weight: 400;
    }
  }

  .success-message {
    color: $white-alpha;
  }

  .error {
    color: $red500;
    margin-top: 10px;
    animation: fadeIn 0.5s ease-in-out;
  }

  .invalid {
    border-color: $red500;
  }

  .button {
    @include fontSize(16px, 150%);

    padding: 16px 32px;
    color: $gray900;
    background-color: $yellow300;
    border-radius: 8px;
    border: none;
    font-family: $default-font;
    font-weight: 500;
    outline: none;
    cursor: pointer;

    &:disabled {
      background-color: $gray200;
    }

    @include tablet {
      width: 100%;
    }
  }

  @include tablet {
    flex-wrap: wrap;
  }
}
