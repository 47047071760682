@import 'styles/utils/sizes';
@import 'styles/utils/colors';
@import 'styles/utils/mixins';

.footer-foot {
  @include flex(column);

  gap: 48px;
  margin: 0 auto;
  padding: 64px 32px 48px;
  max-width: calc($wide-content-width + 64px);

  @include phone {
    padding: 48px 16px;
  }
}

.social-links {
  @include flex(row, center, center);

  gap: 16px;

  @include phone {
    @include flex(row, flex-start, flex-start, wrap);

    gap: 24px;
  }
}

.social-link {
  @include flex(row, center, center);

  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: $gray800;
}

.bottom {
  @include flex(row, space-between, center);

  color: $white-alpha;

  p {
    @include fontSize(14px, 150%);
  }

  @include phone {
    @include flex(column, flex-start, flex-start);

    gap: 32px;
  }
}

.privacy-policy {
  @include flex(row, space-between, center);

  gap: 32px;

  .link {
    @include fontSize(14px, 150%);

    color: currentcolor;
    text-decoration: underline;
  }
}
