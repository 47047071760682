@import 'styles/utils/sizes';
@import 'styles/utils/colors';
@import 'styles/utils/mixins';

.footer-body {
  @include flex(row, initial, flex-start);

  gap: 64px;
  margin: 0 auto;
  padding: 0 32px;
  width: 100%;
  max-width: calc($wide-content-width + 64px);
  color: $gray0;

  .white-text {
    @include fontSize(14px, 150%);

    color: $white;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .grey-text {
    @include fontSize(14px, 150%);

    color: $white-alpha;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .link-text {
    @include fontSize(14px, 150%);

    color: $white-alpha;
    font-weight: 400;
    margin-bottom: 16px;
    text-decoration: underline;
  }

  .large-text {
    @include fontSize(24px, 150%);

    color: $white;
    font-weight: 600;
  }

  .contact-links {
    display: flex;
    gap: 16px;
  }

  .block-1 {
    @include flex(column, initial, flex-start);

    flex: 0 0 308px;
    gap: 32px;

    .logo {
      @include flex(row, flex-start, center);

      gap: 15px;
    }

    .clutch-review {
      width: 202px;
      height: 44px;
    }
  }

  .block-2 {
    @include flex(row, initial, initial, wrap);

    gap: 32px;
    flex: 1 1 100%;

    & > div {
      flex: 0 0 398px;

      @include tablet {
        flex: 100%;
      }
    }

    &__content {
      @include flex(column, flex-start, flex-start);

      gap: 8px;
    }
  }

  @include tablet {
    gap: 48px;
    flex-wrap: wrap;
  }

  @include phone {
    padding: 0 16px;
  }
}
