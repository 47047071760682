@import 'styles/utils/colors';
@import 'styles/utils/sizes';
@import 'styles/utils/mixins';

.footer-header {
  @include flex;

  gap: 32px;
  margin: 0 auto;
  padding: 80px 32px 64px;
  width: 100%;
  max-width: calc($wide-content-width + 64px);

  .text-block {
    flex: 1 1 50%;
  }

  .heading-text {
    @include fontSize(24px, 150%);

    margin: 0 0 8px;
    color: $white;
    font-weight: 600;
  }

  .normal-text {
    @include fontSize(16px, 150%);

    color: $white-alpha;
    font-weight: 400;

    @include phone {
      @include fontSize(14px, 150%);
    }
  }

  @include below-tablet {
    @include flex(column);
  }

  @include phone {
    padding: 48px 16px;
  }
}
